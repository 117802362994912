<template>
  <div class="relative">
    <!-- Badge -->
    <div
      v-if="props.count"
      class="absolute -right-2 -top-2 rounded-full w-4 h-4 flex justify-center items-center bg-primary text-white text-[9px]"
    >
      {{ props.count > 9 ? '9+' : props.count }}
    </div>
    <svg
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="h-full w-full"
    >
      <path
        d="M24.3721 9.28081C24.3721 16.841 12.999 22.6379 12.999 22.6379C12.999 22.6379 1.62695 16.8409 1.62695 9.28081C1.62695 1.72069 9.84054 2.09173 12.999 6.63135C16.1852 2.05188 24.3721 1.72067 24.3721 9.28081Z"
        stroke="currentColor"
        stroke-width="2"
      />
    </svg>
  </div>
</template>

<script setup>
const props = defineProps({
  count: { type: Number, default: 0 }
})
</script>
